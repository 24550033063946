.wrap {
  font-family: var(--Lato);
  max-width: 100vw;
  min-height: 100vh;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  color:var(--color-black);
  position: relative;
  &__main {
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}

.container {
  width: 100%;
  max-width: 192rem;
  margin: 0 auto;
  padding: 0 10rem;
}

.section{
  &__button{
    padding: 2.4rem 12rem;
    width: 100%;
    text-align: center;
    position: relative;
    display: block;
    color:white;
    background-color: var(--color-primary);
    font: 700 2rem / 2.6rem var(--UaBrand);
    border-radius: 0.8rem;
    cursor: pointer;
    transition: 0.2s all ease;
    width: max-content;
    &:hover{
      background-color: var(--color-black);
    }
  }
  &__title{
    font: 700 5.6rem / 7.3rem var(--UaBrand);
  }
  &__text{
    font: 400 2rem / 3.2rem var(--Lato);
  }
  &__primary{
    background-color: var(--color-primary);
    .section__text, .section__title{
      color:var(--color-white)
    }
  }
}

.header{
  position: relative;
  .container{
    padding: 3rem 10rem;
    max-width: 192rem;
  }
  &__block{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__logo{
    width: 16.76rem;
    height: 4.8rem;
    display: block;
  }

  &__burger {
    width: 2rem;
    height: 1.4rem;
    display: none;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    gap: 0.6rem;
    &-open {
      span{
        display: block;
        position: absolute;
        top: 50%;
        margin: 0;
        transition: .2s ease;
        &:nth-child(1) {
          transform: rotate(-45deg);
        }
        &:nth-child(2) {
          transform: rotate(45deg);
        }
        &:nth-child(3) {
          display: none;
        }
      }
    }
    & > * {
      display: block;
      width: 2rem;
      height: 0.1rem;
      background: var(--color-black);
      border-radius: 0.2rem;
      transition: .2s ease;
    }
  }
  &__menu{
    display: flex;
    gap:6rem;
    align-items: center;
    &-list{
      display: flex;
      gap:6rem;
      align-items: center;
      & > *{
        font: 500 2rem / 2.4rem var(--Lato);
        text-align: center;
        transition: 0.2s ease;
        &:hover{
          color:var(--color-primary)
        }
      }

    }
    &-show{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      gap:20vh;
      overflow: hidden;
      background-color: white;
      width: 101vw;
      height: calc( 100vh - 7.1rem);
      top: 7.1rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 4;
      position: absolute;
      padding-bottom: 5rem;
    }
  }
  &__language{
    display: flex;
    gap:0.8rem;
    align-items: center;
    &> *{
      font: 500 2rem / 2.4rem var(--Lato);
    }
    img{
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}




.hidden{
  overflow: hidden;
}



.banner{
  position: relative;
  &:after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.65);
    z-index: -1;
  }
  &__img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &__block{
    padding: 26.1rem 0 24.1rem;
    display: flex;
    flex-direction: column;
    gap:2.4rem;
    align-items: center;
    & > *{
      color: white;
      text-align: center;
    }
  }
  &__top{
    font: 400 1.6rem / 2.6rem var(--Lato);
    text-transform: uppercase;
  }
  &__title{
    font: 700 7.6rem / 9.88rem var(--UaBrand);
  }
  &__subtitle{
    font: 400 2rem / 3.2rem var(--Lato);
  }
  .section__button{
    margin: 1.6rem auto 0;
  }
}


.why{
  position: relative;

  .container{
    padding: 10rem 10rem 24rem;
  }
  &:after{
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    bottom: -0.1rem;
    z-index: 1;
    height: 48rem;
    background-color: var(--color-white);

  }
  &__block{
    display: flex;
    gap:17.5rem;
    position: relative;
    z-index: 2;
  }
  &__info{
    width: 41%;
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap:2.4rem;
  }
  &__props{
    width: 50%;
    border-radius: 2rem;
    padding: 8rem;
    display: flex;
    gap:10rem;
    background-color: var(--color-white);
    flex-direction: column;
    border-radius: 2rem;
    box-shadow: 0 2rem 8rem 0 rgba(0, 0, 0, 0.08);
    &-item{
     display: flex;
      gap:6rem;
      align-items: center;

    }
    &-img{
      width: 8rem;
      height: 8rem;
      min-width: 8rem;
    }
    &-desc{
      h3{
        font: 700 2.8rem / 3.6rem var(--UaBrand);
        margin-bottom: 1.2rem;
      }
      p{
        color:#595858;
        font: 400 1.6rem / 2.6rem var(--Lato);

      }
    }
  }
  &__counter{
    display: flex;
    gap:9.9rem;
    position: relative;
    z-index: 2;
    margin-top: -24rem;
    &-item{
      display: flex;
      flex-direction: column;
      gap:1.2rem;

      h3{
        font: 700 5.6rem / 7.2rem var(--UaBrand);
      }
      p{
        font: 400 1.4rem / 2.3rem var(--Lato);
        color:var(--color-text);
        text-transform: uppercase;
      }
    }

  }

}

.offer{
  .container{
    padding: 10rem 10rem 8rem;
  }
  &__list{
    display: flex;
    gap:3rem;

  }
  &__item{
    padding: 8rem 4rem;
    width: calc((100% - 6rem)/3);
    background-color: white;
    border-radius: 2rem;
    box-shadow: 0 2rem 8rem 0 rgba(0, 0, 0, 0.1);
    &:hover{
      background: linear-gradient(0deg, rgba(51, 102, 234, 0.08) 0%, rgba(51, 102, 234, 0.08) 100%), #FFF;
    }
  }
  &__img{
    width:12rem;
    height: 12rem;
    margin: 0 auto 4rem;
  }
  &__title{
    font: 700 2.4rem / 3.1rem var(--UaBrand);
    text-align: center;
    margin-bottom:1.6rem;
  }
  &__desc{
    font: 400 2rem / 3.2rem var(--Lato);
    color:#595858;
    text-align: center;
  }
  .section__title{
    text-align: center;
    margin-bottom: 2.4rem;
  }
  .section__text{
    text-align: center;
    max-width: 113.6rem;
    margin: 0 auto 6rem;
  }
}

.contact{
  .container{
    padding: 10rem 10rem 5.8rem;
  }
  &__block{
    display: flex;
    gap:17.5rem;
    justify-content: space-between;
  }
  &__info{
    display: flex;
    flex-direction: column;
    gap:2.4rem;
    margin-top: 4rem;
    width: 89.7%;
  }
  &__form{
    width: 100%;
    background-color: var(--color-white);
    border-radius: 2rem;
    padding: 4rem 6rem;
    display: flex;
    flex-direction: column;
    gap:2.2rem;
    .section__button{
      padding: 1.6rem 12rem;
      margin: 1rem auto 0;
    }
  }
}

.form{
  &__input{
    display: flex;
    flex-direction: column;
    gap:0.8rem;
    position: relative;
    label{
      font: 700 1.4rem / 1.7rem var(--UaBrand);
    }
    input, textarea{
      border-radius:0.8rem;
      border: 0.1rem solid  #D3CECE;
      padding: 1.6rem;
      font: 400 1.6rem / 1.9rem var(--Lato);
      &::placeholder{
        color:#B6B6B6;
      }
      &.error{
        border-color:var(--color-error) ;
        color:var(--color-error)
      }
    }
    textarea{
      min-height: 16rem;
    }
  }
}
label.error{
  position: absolute;
  bottom: -2rem;
  color: var(--color-error);
  font: 400 1.2rem / normal var(--Lato);
  right: 0;
}

.review{
  .container{
    padding: 8rem 10rem 10rem 0;
  }
  &__block{
    display: flex;
    gap:17.6rem;
    align-items:center;
    justify-content: space-between;

  }
  &__info{
    width: 36%;
    display: flex;
    flex-direction: column;
    gap:2.4rem;

  }
  &__slider{
    padding:10rem ;
    min-height: 72rem;
    width: 69%;
  }
  &__text{
    display: flex;
    flex-direction: column;
    gap:1.6rem;
    padding-top: 7.4rem;
    &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4.4rem;
      height: 4.4rem;
      background: url("../img/quotes.svg") center/4.4rem 4.4rem no-repeat;
    }
    & > *{
      font: 600 2.8rem / 3.6rem var(--Lato);
      color:var(--color-text)
    }
  }
  &__list{
    height: 100%;
  }
  &__item{
    display: flex;
    flex-direction: column;
    gap:1rem;
    justify-content: space-between;
    height: 100%;
    min-height: 52rem;
  }
  &__name{
    font: 700 2.4rem / 3.1rem var(--UaBrand);
  }
  &__star{
    display: flex;
    gap:0.8rem;
    margin-bottom: 1.2rem;
    & > *{
      width: 3rem;
      height: 3rem;
    }
  }
  &__nav{
    position: relative;
    display: flex;
    gap:2rem;
    margin-bottom: 1.6rem;
    & > * {
      display: block;
      width: 6.4rem;
      height: 6.4rem;
      background-color: #B6B6B6;
      border-radius: 100%;
      position: relative;
      left: unset;
      right: unset;
      transition: .2s ease;
      &:hover{
        background-color: var(--color-primary);
      }
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 4.4rem;
        height: 4.4rem;
        background: url("../img/arr.svg") center/4.4rem 4.4rem no-repeat;
      }
    }

  }
  &__prev{
    &:after{
      transform: translate(-50%,-50%) rotate(-180deg);
    }
  }
  &__slider{
    border-radius: 2rem;
    background: #FFF;
    box-shadow: 2rem 0.1rem 2rem 0 rgba(0, 0, 0, 0.06);
  }
}
.footer {
  background: #091315;
  color:var(--color-white);
  .container{
    padding: 8rem 10rem 6.2rem;
  }
  &__block{
    display: flex;
    gap:2rem;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__info{
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    &-item{
      padding-left: 3.6rem;
      font: 400 1.6rem / normal var(--Lato);
      position: relative;
      &:after{
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 2rem;
        height: 2rem;
      }
    }
  }
  &__phone:after{
    background: url("../img/phone.svg") center/2rem 2rem no-repeat;
  }
  &__email:after{
    background: url("../img/email.svg") center/2rem 2rem no-repeat;
  }
  &__address:after{
    background: url("../img/location.svg") center/2rem 2rem no-repeat;
  }
  &__menu{
    display: flex;
    gap:8rem;
    align-items: center;
    margin-left: -5rem;
    & > *{
      padding: 2.4rem 0;
      text-transform: capitalize;
      font: 400 2rem / normal var(--Lato);
      transition: 0.2s ease;
      &:hover{
        color:var(--color-primary)
      }
    }
  }
  &__logo{
    height: 8rem;
    width: 27.94rem;
  }
  &__bottom{
    margin-top: 8rem;
    display: flex;
    align-items: center;
    gap:1.6rem;
    justify-content: center;

    & > *{
      font: 400 1.4rem / normal var(--Lato);
      color: #B6B6B6;
    }
  }


}





input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}




.modal {
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  &__close {
    position: absolute;
    top: 2.4rem;
    right: 2.4rem;
    width: 3rem;
    height: 3rem;
    cursor: pointer;
    z-index: 5;
  }
  &__wrap {
    position: relative;
    width: 100%;
    height: 100%;
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background: #000;
      opacity: 0.50;
      top: 0;
      left: 0;
    }
  }
  &__content {


    border-radius: 2rem;
    background: #FFF;
    box-shadow: 0 2rem 8rem 0 rgba(0, 0, 0, 0.15);
    max-width: 84.5rem;
    width: 100%;
    overflow: hidden;
    position: absolute;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    max-height: 95vh;
    transform: translate(-50%, -50%);
    z-index: 6;
  }
  &__overflow {
    padding: 6rem ;
    width: 100%;
    max-height: 95vh;
    overflow: auto;
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 0.4rem;
    }
    &::-webkit-scrollbar-thumb {
      background-color: var(--color-primary);
    }
  }
  &__img{
    width: 28rem;
    height: 28rem;
    margin: 0 auto 4rem;
  }
  &__title{
    font: 700 2.8rem / 130% var(--UaBrand);
    text-align: center;
    margin-bottom: 1.6rem;
  }
  &__subtitle{
    color:var(--color-text);
    font: 700 2rem / 160% var(--Lato);
    text-align: center;
  }
}


@media only screen and (max-width: 1440px) {

}

@media only screen and (max-width: 1360px) {


}
@media only screen and (max-width: 1024px) {

}


@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 666px) {
  .wrap .container{
    padding: 4rem 1.6rem;
  }
  .banner .container{
    padding: 13.9rem 1.6rem 13.6rem;
  }
  .banner__top{
    font-size: 1.2rem;
    line-height: 160%;
  }
  .banner__title{
    font-size: 3.6rem;
    line-height: 130%;
  }
  .banner__subtitle{
    font-size: 1.6rem;
    line-height: 160%;
  }
  .banner__block{
   padding: 0;
  }
  .section__button{
    font-size: 1.6rem;
    line-height: 130%;

  }


  .header__burger{
    display: flex;
  }
  .header__logo{
    width: 11.1754rem;
    height: 3.2rem;

  }
  .header{
    position: relative;
  }
  .header__mob{
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    align-items: center;
  }
  .header .container{
    padding: 2rem 1.6rem;
  }
  .header__menu{
    display: none;
  }

  .header__menu-show{
    display: flex;
    //width: 100vw;
    //top:8rem;
    //height: calc(100vh - 8rem);
    //position: absolute;
  }
  .header__menu-list{
    flex-direction: column;
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  .header__language{
   justify-content: center;
  }
  .why__block{
   flex-direction: column;
    gap:4rem;
  }
  .why__info{
   width: 100%;
    margin: 0;
    gap:1.6rem;
    & > *{
      text-align: center;
    }
  }
  .why__props{
    width: 100%;
    padding: 4rem 2.4rem;
    gap:4rem;
  }
  .why__props-item{
    flex-direction: column;
    gap:3rem;
  }
  .why__props-desc h3{
    font-size: 2rem;
    line-height: 130%;
    text-align: center;
  }
  .why__props-desc p{
    font-size: 1.4rem;
    line-height: 160%;
    text-align: center;
  }
  .why__counter{
    margin: 0;
    gap:6.4rem;
    flex-direction: column;
    align-items: center;
  }
  .section__title{
    font-size: 2.8rem;
    line-height: 130%;
  }
  .section__text{
    font-size: 1.6rem;
    line-height: 160%;
  }
  .why__counter-item{
      text-align: center;
  }
  .why__counter-item h3{
    font-size: 4rem;
    line-height: 130%;
  }
  .why__props-img{
    width: 6.4rem;
    height: 6.4rem;
    min-width: 6.4rem;
  }
  .why__counter-item p{
    font-size: 1.2rem;
    line-height: 160%;
  }
  .why:after{
    display: none;
  }
  .why__counter{
    margin: 6rem -1.6rem -0.1rem;
    padding: 10rem 0 6rem;
    background-color: var(--color-white);
  }
  .why .container{
    padding-bottom: 0;
  }
  .offer__list{
    flex-direction: column;
    gap:2.4rem;
  }
  .offer__item{
    width: 100%;
    padding: 4rem 2.4rem;
  }
  .offer__img{
    width: 6.4rem;
    height: 6.4rem;
    margin-bottom: 2.4rem;
  }
  .offer__title{
    font-size: 2rem;
    line-height: 2.6rem;
  }
  .offer__desc{
    font-size: 1.4rem;
    line-height: 160%;
  }
  .offer .section__title{
    margin-bottom: 1.6rem;
  }
  .offer .section__text{
    margin-bottom: 4rem;
  }

  .contact__block{
    flex-direction: column;
  }
  .banner .section__button{
    max-width: 28.8rem;
    width: 100%;
    padding: 1.6rem;
  }

  .review__block{
    flex-direction: column-reverse;
    gap:2.4rem;
    & >*{
      width: 100%;
    }
  }
  .review__slider{
    padding: 3.6rem 1.6rem;
  }
  .review__text{
    padding-top: 6rem;
  }
  .review__text > *{
    font-size: 2rem;
    line-height: 130%;
  }
  .review__nav{
    display: none;
  }
  .review__star > *{
    width: 2rem;
    height: 2rem;
  }
  .review__info{
    text-align: center;
    gap:1.6rem;
  }
  .review__name{
    font-size: 2rem;
    line-height: 130%;
  }
  .review__item{
    gap:3rem;
  }
  .review__slider, .review__item{
    min-height: auto;
  }
  .review__slider{
    width: 100vw;
    margin: 0 -1.6rem;
    border-radius: 0;
    box-shadow: 2rem 0.1rem 6rem 0 rgba(0, 0, 0, 0.06);
  }
  .contact .container{
    padding: 6rem 1.6rem;
  }
  .contact__block{
    gap:4rem;
  }
  .contact__info{
    width: 100%;
    margin: 0;
    gap:1.6rem;
  }
  .contact__form{
    padding: 3.2rem 2.4rem 2.4rem;
    gap:2.4rem;
  }
  .contact__form .section__button{
    padding: 1.6rem;
    max-width: 24rem;
    width: 100%;
    margin-top: 0;
  }
  .form__input label{
    font-size: 1.2rem;
    line-height: normal;
  }
  .footer .container{
    padding: 6rem 2.4rem 3rem;
  }
  .footer__block{
    flex-direction: column;
    gap:8.3rem;

  }
  .footer__logo{
    margin: 0 auto;
    width: 13.9692rem;
    height: 4rem;

  }
  .footer__menu{
    gap:3.2rem;
    margin: 0  auto;
    flex-direction: column;
    align-items: center;

  }
  .footer__info{
    margin: 0 auto;
  }
  .footer__info-item{
    font-size: 1.2rem;
    padding-left: 3.2rem;
  }
  .footer__menu > *{
    padding: 0;
    text-align: center;
    font-size: 1.6rem;
    line-height: normal;

  }
  .footer__info-item:after{
    width: 1.6rem;
    height: 1.6rem;
  }
  .footer__phone:after {
    background: url(../img/phone.svg) center / 1.6rem 1.6rem no-repeat;
  }
  .footer__email:after {
    background: url(../img/email.svg) center / 1.6rem 1.6rem no-repeat;
  }
  .footer__address:after {
    background: url(../img/location.svg) center / 1.6rem 1.6rem no-repeat;
  }
  .footer__bottom{
    margin-top: 6rem;
    flex-wrap: wrap;
    gap:0.8rem;
  }


}
@media only screen and (max-width: 480px) {

}

